import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { PageWrapper } from '~components/Core';
import HeaderButton from '~sections/app/Header';
import { Link } from '~components';
import FooterFour from '~sections/app/FooterFour';
import { Container } from 'react-bootstrap';

const header = {
  headerClasses:
    'site-header site-header--menu-end light-header site-header--sticky',
  containerFluid: false,
  buttonBlock: (
    <HeaderButton
      as={Link}
      className="d-none d-sm-flex"
      children="App Login"
      target="_blank"
      to="https://app.actovos.com"
    />
  ),
};

export default function ({ pageContext: { content } }) {
  return (
    <PageWrapper headerConfig={header}>
      <Container style={{ marginTop: 100 }}>
        {renderRichText(content)}
      </Container>
      <FooterFour />
    </PageWrapper>
  );
}
