import { Link } from '~components';
import HubspotForm from 'react-hubspot-form';
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Images } from '~data';
import Footer from './style';
import Logo from '../../../components/Core/logos/actovos';

export default function FooterFour() {
  return (
    <Footer mt="40px">
      <Container>
        <Footer.Box pb="30px">
          <Row className="justify-content-center justify-content-lg-between">
            <Col xs="12" className="col-6 col-xxs-4 col-lg-4 col-md-5 col-xs-4">
              <Footer.Logo>
                {/* Brand Logo*/}
                <Footer.Box mb="30px" mbMD="0">
                  <Link to="#">
                    <Logo color="#136de8" width={200} />
                  </Link>
                </Footer.Box>
              </Footer.Logo>
            </Col>
            <Col xs="12" className="col-lg-4 col-md-6 col-xs-9">
              <Footer.Widgets>
                <Footer.Title>Newsletter</Footer.Title>
                <Footer.Text>
                  Get the latest updates right at your inbox.
                </Footer.Text>
                {/* Newsletter */}
                <Footer.Newsletter>
                  <HubspotForm
                    portalId="7603016"
                    formId="536b2c90-167e-4ac2-8af1-50689c800c4d"
                    onSubmit={() => console.log('Submit!')}
                    onReady={form => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                  />

                  {/* <form action="/.">
                    <input type="text" placeholder="Enter Your Email" />
                    <Footer.NewsletterBtn
                      backgroundColor="#136de8"
                      hoverTextColor="#fff"
                      textColor="#fff"
                      sizeX="150px"
                      sizeY="56px"
                      type="submit"
                    >
                      Subscribe
                    </Footer.NewsletterBtn>
                  </form> */}
                </Footer.Newsletter>
                {/*/ .Newsletter */}
              </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-lg-4 col-md-7">
              <Footer.Menu>
                <Footer.MenuItems>
                  <Link to="/">Home</Link>{' '}
                </Footer.MenuItems>
                {/* <Footer.MenuItems>
                  {' '}
                  <Link to="/plans">Plans</Link>{' '}
                </Footer.MenuItems> */}
                <Footer.MenuItems>
                  <Link to="/terms-of-service">Terms of Service</Link>{' '}
                </Footer.MenuItems>
                <Footer.MenuItems>
                  <Link to="/privacy-policy">Privacy Policy</Link>{' '}
                </Footer.MenuItems>
              </Footer.Menu>
            </Col>
          </Row>
        </Footer.Box>
        <Footer.Copyright>
          <Footer.CopyrightText>
            © 2021 Actovos. All Rights Reserved
          </Footer.CopyrightText>
          {/* <Footer.SocialShare>
            <Footer.SocialShareItem>
              <a href="#">
                <i className="fab fa-facebook-square" />
              </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
              <a href="#">
                <i className="fab fa-twitter" />
              </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
              <a href="#">
                <i className="fab fa-instagram" />
              </a>
            </Footer.SocialShareItem>
            <Footer.SocialShareItem>
              <a href="#">
                <i className="fab fa-linkedin" />
              </a>
            </Footer.SocialShareItem>
          </Footer.SocialShare> */}
        </Footer.Copyright>
      </Container>
    </Footer>
  );
}
